<template>
  <v-container>
    <house-funds-form :loading="isSendingData" @submit="(data) => createHouseFund(data)" />
  </v-container>
</template>

<script>
import api from '@/services/api';
import HouseFundsForm from './form/HouseFundsForm.vue';

export default {
  name: 'HouseFundsCreateView',

  components: {
    HouseFundsForm,
  },

  data: () => ({
    isSendingData: false,
  }),

  methods: {
    async createHouseFund(data) {
      this.isSendingData = true;

      try {
        const response = await api.houseFunds.createHouseFund(data);
        await this.updateFund(data); // Courtesy update of fund in assets collection

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Fundo criado',
          message: 'Os dados do fundo foram cadastrados com sucesso.',
        });

        this.$router.replace(`/housefunds/${response.data._id}`);
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    async updateFund(data) {
      const renameBusinessDays = (daysType) => (daysType === 'WORKDAYS' ? 'BUSINESS_DAYS' : daysType);

      // There are some known mismatches between assets (left) and portfolios (right) models
      // 'Convertion' and 'Additionl' are expected typos
      const assetsFundsUpdate = {
        custodyBank: data?.custodyAccount?.bank,
        custodyAgency: data?.custodyAccount?.agency,
        custodyAccount: data?.custodyAccount?.account,
        purchase: {
          daysToConvertion: data?.purchase?.daysToConversion,
          daysToConvertionType: renameBusinessDays(data?.purchase?.daysToConversionType),
          daysToSettlement: data?.purchase?.daysToSettlement,
          daysToSettlementType: renameBusinessDays(data?.purchase?.daysToSettlementType),
          minimumInitialPurchase: data?.purchase?.minimumInitialPurchase,
          minimumAdditionlPurchase: data?.purchase?.minimumAdditionalPurchase,
        },
        withdrawal: {
          daysToConvertion: data?.withdrawal?.daysToConversion,
          daysToConvertionType: renameBusinessDays(data?.withdrawal?.daysToConversionType),
          daysToSettlement: data?.withdrawal?.daysToSettlement,
          daysToSettlementType: renameBusinessDays(data?.withdrawal?.daysToSettlementType),
          minimumWithdrawal: data?.withdrawal?.minimumWithdrawal,
        },
      };

      const response = await api.funds.updateById(data.fundId, assetsFundsUpdate);
      return response;
    },
  },
};
</script>

import { isNil, startCase } from 'lodash';

export function formatDate(dateString?: string) {
  if (isNil(dateString)) {
    return '-';
  }

  // Cannot use "toLocaleDateString" due to timezone errors
  return dateString.substring(0, 10).split('-').reverse().join('/');
}

export function formatTitle(text?: string) {
  if (isNil(text)) {
    return '-';
  }

  return startCase(text.replaceAll('_', ' ').toLowerCase());
}

export function formatNumber(value?: number, formatOptions: Intl.NumberFormatOptions = {}) {
  if (isNil(value)) {
    return '-';
  }

  const intlObj = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 8,
    ...formatOptions,
  });

  return intlObj.format(value);
}

export function formatMoney(value?: number, formatOptions: Intl.NumberFormatOptions = {}) {
  return formatNumber(value, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...formatOptions,
  });
}

export function formatCurrency(value?: number, currency = 'BRL', formatOptions: Intl.NumberFormatOptions = {}) {
  return `${currency} ${formatMoney(value, formatOptions)}`;
}

export function formatQuota(value?: number, formatOptions: Intl.NumberFormatOptions = {}) {
  return formatNumber(value, {
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
    ...formatOptions,
  });
}

export function formatPercentage(value?: number, formatOptions: Intl.NumberFormatOptions = {}) {
  return formatNumber(value, {
    maximumFractionDigits: 2,
    style: 'percent',
    ...formatOptions,
  });
}

export function formatPerformance(value?: number, formatOptions: Intl.NumberFormatOptions = {}) {
  return formatPercentage(value, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...formatOptions,
  });
}

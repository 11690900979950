<template>
  <v-list two-line dense>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Dias para conversão
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ daysToConversion }} {{ daysToConversionType }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Dias para liquidação
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ daysToSettlement }} {{ daysToSettlementType }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Mínimo para 1ª aplicação
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ minimumInitialPurchase }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Mínimo para aplicações adicionais
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ minimumAdditionalPurchase }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { formatMoney } from '@/utils/format-utils';

const getValue = (val) => val?.$numberDecimal ?? val;

const getDayTypeString = (type) => (type === 'WORKDAYS' ? 'dias úteis' : 'dias corridos');

export default {
  name: 'HouseFundPurchaseInfo',

  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    daysToConversion: (vm) => vm.info?.daysToConversion,
    daysToConversionType: (vm) => getDayTypeString(vm.info?.daysToConversionType),
    daysToSettlement: (vm) => vm.info?.daysToSettlement,
    daysToSettlementType: (vm) => getDayTypeString(vm.info?.daysToSettlementType),
    minimumInitialPurchase: (vm) => formatMoney(getValue(vm.info?.minimumInitialPurchase)),
    minimumAdditionalPurchase: (vm) => formatMoney(getValue(vm.info?.minimumAdditionlPurchase)),
  },
};
</script>

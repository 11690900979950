<template>
  <v-form>
    <v-row dense>
      <v-col cols="12" sm="9" class="scrollable">
        <v-row v-for="(item, index) of brokerAccounts" :key="index" dense>
          <v-col cols="12" sm="4">
            <v-text-field
              dense
              label="Conta"
              v-maska="'#########'"
              :value="item.accountNumber"
              @input="(accountNumber) => updateAccountNumber(index, accountNumber)"
            />
          </v-col>
          <v-col cols="12" sm="7">
            <brokers-selector
              dense
              return-object
              label="Corretora"
              item-value="_id"
              item-text="codeAndName"
              :value="item.brokerId"
              @input="(broker) => updateBroker(index, broker)"
            />
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn icon color="error" @click="() => removeItem(index)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="3" class="align-content-center text-right">
        <v-btn small outlined color="primary" @click="addItem">
          <v-icon small> mdi-plus </v-icon>
          Nova conta
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BrokersSelector from '@/components/global/BrokersSelector.vue';

const formSchema = {
  accountNumber: null,
  brokerId: null,
  brokerCode: null,
};

export default {
  name: 'BrokerAccountsInput',

  components: {
    BrokersSelector,
  },

  props: {
    initialData: {
      type: Array,
      default: () => [],
    },
  },

  data: (vm) => ({
    brokerAccounts: vm.initialData.length ? [...vm.initialData] : [{ ...formSchema }],
  }),

  watch: {
    brokerAccountsFulfilled: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update', newValue);
      },
    },
  },

  computed: {
    brokerAccountsFulfilled: (vm) => vm.brokerAccounts.filter((item) => item.accountNumber && item.brokerId),
  },

  methods: {
    updateAccountNumber(index, accountNumber) {
      this.updateItem(index, { ...this.brokerAccounts[index], accountNumber });
    },

    updateBroker(index, broker) {
      this.updateItem(index, {
        ...this.brokerAccounts[index],
        brokerId: broker._id,
        brokerCode: broker.code,
      });
    },

    addItem() {
      this.brokerAccounts = [...this.brokerAccounts, { ...formSchema }];
    },

    updateItem(index, data) {
      this.brokerAccounts.splice(index, 1, data);
    },

    removeItem(index) {
      this.brokerAccounts.splice(index, 1);
    },

    discardChanges() {
      this.brokerAccounts = this.initialData.length ? [...this.initialData] : [{ ...formSchema }];
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollable {
  padding-top: 10px;
  max-height: 200px;
  overflow-y: auto;
}
</style>

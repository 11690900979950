<template>
  <v-card :loading="loading">
    <v-card-title>
      Configurações
    </v-card-title>

    <v-container>
      <v-row dense>
        <v-col cols="12" sm="auto">
          <v-tabs :vertical="$vuetify.breakpoint.smAndUp" v-model="calcConfigIndex">
            <v-tab>
              Inserir
            </v-tab>

            <template v-for="item of configListTabs">
              <v-tab :key="item.key">
                {{ item.text }}
              </v-tab>
            </template>
          </v-tabs>
        </v-col>

        <v-divider :vertical="$vuetify.breakpoint.smAndUp" />

        <v-col>
          <calc-config-form
            ref="configForm"

            :isLoading="isSendingData"
            :initialData="formData"

            @submit="(data) => handleUpsertSubmit(data)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { isNil, round } from 'lodash';
import { formatDate } from '@/utils/format-utils';
import api from '@/services/api';

import CalcConfigForm from './CalcConfigForm.vue';

export default {
  name: 'HouseFundCalcConfig',

  components: {
    CalcConfigForm,
  },

  props: {
    houseFundId: {
      type: String,
      required: true,
    },

    configList: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    calcConfigIndex: 1,
    isSendingData: false,
  }),

  computed: {
    configListTabs: (vm) => vm.configList.map((item) => ({ key: item._id, text: formatDate(item.startDate) })),

    selectedItem: (vm) => vm.configList[vm.calcConfigIndex - 1],

    formData: (vm) => {
      if (!vm.selectedItem) {
        return {};
      }

      const getTrueValue = (field) => field.$numberDecimal ?? field;
      const decimalToPercentage = (val) => (round(val * 100, 8));
      const getDate = (ISODate) => ISODate.substring(0, 10);

      const data = { ...vm.selectedItem };

      if (vm.selectedItem.pFeeTolerance) {
        data.pFeeTolerance = decimalToPercentage(getTrueValue(vm.selectedItem.pFeeTolerance));
      }

      if (vm.selectedItem.sharesValuesTolerance) {
        data.sharesValuesTolerance = decimalToPercentage(getTrueValue(vm.selectedItem.sharesValuesTolerance));
      }

      if (vm.selectedItem.shareQuantityTolerance) {
        data.shareQuantityTolerance = getTrueValue(vm.selectedItem.shareQuantityTolerance);
      }

      if (vm.selectedItem.fundsQuantityTolerance) {
        data.fundsQuantityTolerance = getTrueValue(vm.selectedItem.fundsQuantityTolerance);
      }

      if (vm.selectedItem.startDate) {
        data.startDate = getDate(vm.selectedItem.startDate);
      }

      if (vm.selectedItem.endDate) {
        data.endDate = getDate(vm.selectedItem.endDate);
      }

      return data;
    },
  },

  watch: {
    selectedItem() {
      this.$nextTick(() => this.$refs.configForm?.discardChanges());
    },
  },

  methods: {
    fetchData() {
      this.$emit('refresh-data');
    },

    handleUpsertSubmit(newData) {
      if (this.selectedItem) {
        this.updateItem(this.selectedItem._id, newData);
      } else {
        this.createItem(newData);
      }
    },

    // ----------
    //  Creation
    // ----------

    async createItem(rawData) {
      this.isSendingData = true;

      try {
        const newData = {};

        // Remove empty values
        Object.entries(rawData).forEach(([key, value]) => {
          if (!isNil(value) && String(value).length > 0) {
            newData[key] = value;
          }
        });

        await api.houseFunds.addCalcConfig(this.houseFundId, newData);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Ajustes incluídos com sucesso!',
          message: 'As informações foram inseridas corretamente.',
        });

        this.fetchData();
        this.$nextTick(() => this.$refs.configForm?.discardChanges());
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: 'Não foi possível incluir os ajustes',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    // ---------
    //  Edition
    // ---------

    async updateItem(admFeeId, data) {
      this.isSendingData = true;

      try {
        await api.houseFunds.updateCalcConfig(admFeeId, data);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Ajustes atualizados com sucesso!',
          message: 'As informações foram atualizadas corretamente.',
        });

        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: 'Não foi possível editar os ajustes',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },
  },
};
</script>

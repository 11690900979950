var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipAttrs = ref.attrs;
var tooltipEvents = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary","to":("/housefunds/" + (item._id))}},'v-btn',tooltipAttrs,false),tooltipEvents),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-magnify ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Ver detalhes ")]},proxy:true}],null,true)})]}},{key:"item.shortName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipEvents = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",staticStyle:{"max-width":"180px"}},'div',tooltipAttrs,false),tooltipEvents),[_vm._v(" "+_vm._s(item.shortName)+" ")])]}},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(item.name)+" ")]},proxy:true}],null,true)})]}},{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.serial)+" ")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.endDate))+" ")]}},{key:"item.startQuota",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.startQuota))+" ")]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
<!--
  TODO:
    - Implement infinite scroll
    - Implement selectAll and ellipsedSelection features
    - Prepare populateItems function for custom $attrs['item-value']
-->
<template>
  <v-autocomplete
    ref="autocomplete"
    v-bind="$attrs"
    v-on="$listeners"
    v-maska="mask"

    :value="value"
    :items="sortedItems"
    cache-items

    :loading="isLoading"
    :search-input.sync="searchQuery"
    :filter="customFilter"

    @update:search-input="($event) => setFundMask($event)"
    @change="() => searchQuery = null"
    @focus="() => fetchData()"
  >

  </v-autocomplete>
</template>

<script>
import api from '@/services/api';
import { getFundMask } from '@/utils/mask-utils';
import { debounce, isNil } from 'lodash';

export default {
  name: 'FundsSelector',

  inheritAttrs: false,

  // NOTE: "value" must be set as a prop for v-model to work properly
  // @see (https://github.com/vuejs/vue/issues/8430)
  props: {
    value: {
      type: null,
      default: null,
    },
  },

  data: () => ({
    searchQuery: null,
    isLoading: false,
    items: [],
    mask: getFundMask(),
  }),

  computed: {
    sortedItems: (vm) => {
      const items = [...vm.items];

      return items.sort((itemA, itemB) => (itemA.shortName > itemB.shortName ? 1 : -1));
    },
  },

  watch: {
    searchQuery(newQuery) {
      if (isNil(newQuery)) {
        return;
      }

      this.fetchData();
    },

    value() {
      this.populateItems(this.value);
    },
  },

  mounted() {
    this.populateItems(this.value);
  },

  methods: {
    setFundMask(input) {
      this.mask = getFundMask(input);
    },

    customFilter(item, queryText) {
      const query = queryText.toLowerCase();

      const rules = [
        item.name.toLowerCase().includes(query),
        item.shortName.toLowerCase().includes(query),
        item.serial === query.replace(/\D/g, ''),
        item.tickerBloomberg.toLowerCase().includes(query),
      ];

      return rules.some(Boolean);
    },

    updateValue(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },

    fetchData: debounce(async function debouncedFetchData() {
      this.isLoading = true;

      try {
        if (this.searchQuery) {
          const { data } = await api.funds.searchv2({ query: this.searchQuery });
          this.items = data.funds;
        } else {
          const { data } = await api.funds.list();
          this.items = data.funds;
        }
      } catch (error) {
        const errorMessage = error.response?.data.message ?? error.message;

        this.$store.dispatch('alert/showAlert', { errorList: [errorMessage] });
      } finally {
        this.isLoading = false;
      }
    }, 750),

    async populateItems(selection) {
      if (!selection?.length) {
        return;
      }

      const cachedItems = this.$refs.autocomplete?.cachedItems ?? [];
      const selectedItems = Array.isArray(selection) ? selection : [selection];

      this.isLoading = true;

      try {
        await Promise.all(selectedItems.map(async (id) => {
          if (cachedItems.some((item) => item._id === id)) {
            return;
          }

          const { data } = await api.funds.byId(id);
          this.items = [data.fund];
          this.updateValue(data.fund);
        }));
      } catch (error) {
        const errorMessage = error.response?.data.message ?? error.message;

        this.$store.dispatch('alert/showAlert', { errorList: [errorMessage] });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <v-form>
    <v-row dense>
      <v-col cols="12">
        <v-row dense class="align-end mb-8">
          <v-col cols="12" sm="2">
            <v-text-field
              label="Banco"
              v-maska="'###'"
              hide-details
              outlined
              dense
              v-model="formFields.bank"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Agência"
              v-maska="'#########'"
              hide-details
              outlined
              dense
              v-model="formFields.agency"
            />
          </v-col>
          <v-col cols="12" sm="6" class="grey lighten-4 rounded">
            <v-row dense>
              <v-col cols="12" class="text-caption text-center"> Conta </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  label="Número"
                  v-maska="'#########'"
                  hide-details
                  outlined
                  dense
                  v-model="supportFields.accountNumber"
                  @input="updateCustodyAccount"
                />
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center"> — </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Dígito"
                  v-maska="'#'"
                  hide-details
                  outlined
                  dense
                  :disabled="!supportFields.accountNumber"
                  v-model="supportFields.accountDigit"
                  @input="updateCustodyAccount"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import fillSchema from '@/utils/fill-schema';

const formSchema = {
  bank: null,
  agency: null,
  account: null,
};

export default {
  name: 'CustodyAccountInput',

  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData ?? {}),

    supportFields: {
      accountNumber: vm.initialData?.account?.split('-')?.[0] ?? null,
      accountDigit: vm.initialData?.account?.split('-')?.[1] ?? null,
    },
  }),

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('update', this.formFields);
      },
    },
  },

  methods: {
    updateCustodyAccount() {
      const { accountNumber, accountDigit } = this.supportFields;

      this.$set(this.formFields, 'account', accountNumber);

      if (!accountNumber) {
        this.supportFields.accountDigit = '';
        return;
      }

      if (accountDigit) {
        this.$set(this.formFields, 'account', `${accountNumber}-${accountDigit}`);
      }
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData ?? {});

      this.supportFields.accountNumber = this.initialData?.account?.split('-')?.[0] ?? null;
      this.supportFields.accountDigit = this.initialData?.account?.split('-')?.[1] ?? null;
    },
  },
};
</script>

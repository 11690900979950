<template>
  <v-data-table
    item-key="_id"
    :headers="headers"

    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item.actions="{ item }">
      <v-btn small icon color="primary" @click="() => $emit('click:edit', item)">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>

      <v-btn small icon color="error" @click="() => $emit('click:delete', item)">
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <template v-slot:item.source="{ item }">
      {{ item.source }}
    </template>

    <template v-slot:item.externalId="{ item }">
      {{ item.externalId }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'ExternalIdsTable',

  inheritAttrs: false,

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rawHeaders: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '98px',
      },

      {
        value: 'source',
        text: 'Origem',
      },

      {
        value: 'externalId',
        text: 'ID Externo',
      },
    ],
  }),

  computed: {
    headers: (vm) => (vm.readonly
      ? vm.rawHeaders.filter((item) => item.value !== 'actions')
      : vm.rawHeaders
    ),
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>

<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs: tooltipAttrs, on: tooltipEvents }">
          <v-btn
            v-bind="tooltipAttrs"
            v-on="tooltipEvents"

            icon
            small
            color="primary"
            :to="`/housefunds/${item._id}`"
          >
            <v-icon size="20">
              mdi-magnify
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Ver detalhes
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.shortName="{ item }">
      <v-tooltip bottom content-class="grey darken-4">
        <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
          <div
            v-on="tooltipEvents"
            v-bind="tooltipAttrs"
            class="text-truncate"
            style="max-width: 180px"
          >
            {{ item.shortName }}
          </div>
        </template>

        <template v-slot:default>
          {{ item.name }}
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.serial="{ item }">
      <span class="text-no-wrap">
        {{ item.serial }}
      </span>
    </template>

    <template v-slot:item.startDate="{ item }">
      {{ formatDate(item.startDate) }}
    </template>

    <template v-slot:item.endDate="{ item }">
      {{ formatDate(item.endDate) }}
    </template>

    <template v-slot:item.startQuota="{ item }">
      {{ formatNumber(item.startQuota) }}
    </template>
  </v-data-table>
</template>

<script>
import { formatDate, formatNumber } from '@/utils/format-utils';

export default {
  name: 'HouseFundsTable',

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: '',
        sortable: false,
        groupable: false,
        width: '60px',
      },

      {
        value: 'shortName',
        text: 'Nome',
      },

      {
        value: 'serial',
        text: 'CNPJ',
      },

      {
        value: 'startDate',
        text: 'Início da Gestão',
      },

      {
        value: 'endDate',
        text: 'Fim da Gestão',
      },

      {
        value: 'startQuota',
        text: 'Cota Inicial',
        align: 'end',
      },
    ],
  }),

  methods: {
    formatDate,
    formatNumber,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>

<template>
  <v-autocomplete
    ref="autocomplete"
    v-bind="$attrs"
    v-on="$listeners"

    cache-items

    :value="value"
    :items="parsedBrokers"
    :loading="isLoading"
    :search-input.sync="searchQuery"
    :filter="customFilter"

    @change="() => (searchQuery = null)"
    @focus="() => fetchData()"
  />
</template>

<script>
import api from '@/services/api';
import { debounce, isNil } from 'lodash';

export default {
  name: 'BrokersSelector',

  inheritAttrs: false,

  // NOTE: "value" must be set as a prop for v-model to work properly
  // @see (https://github.com/vuejs/vue/issues/8430)
  props: {
    value: {
      type: null,
      default: null,
    },
  },

  data: () => ({
    searchQuery: null,
    isLoading: false,
    items: [],
  }),

  computed: {
    parsedBrokers: (vm) => vm.items
      .map((el) => ({ ...el, codeAndName: `(${el.code}) ${el.name}` }))
      .toSorted((a, b) => a.code - b.code),
  },

  watch: {
    searchQuery(newQuery) {
      if (isNil(newQuery)) return;

      this.fetchData();
    },

    value() {
      this.populateItems(this.value);
    },
  },

  mounted() {
    this.populateItems(this.value);
  },

  methods: {
    customFilter(item, queryText) {
      const query = queryText.toLowerCase();

      const rules = [
        item?.name?.toLowerCase()?.includes(query),
        item?.code?.toLowerCase()?.includes(query),
      ];

      return rules.some(Boolean);
    },

    updateValue(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },

    fetchData: debounce(async function debouncedFetchData() {
      this.isLoading = true;

      try {
        if (this.searchQuery) {
          const { data } = await api.brokers.search({ params: { query: this.searchQuery } });
          this.items = data.brokers;
        } else {
          const { data } = await api.brokers.list();
          this.items = data.brokers;
        }
      } catch (error) {
        const errorMessage = error.response?.data.message ?? error.message;
        this.$store.dispatch('alert/showAlert', { errorList: [errorMessage] });
      } finally {
        this.isLoading = false;
      }
    }, 750),

    async populateItems(selection) {
      if (!selection?.length) return;

      const cachedItems = this.$refs.autocomplete?.cachedItems ?? [];
      const selectedItems = Array.isArray(selection) ? selection : [selection];

      this.isLoading = true;

      try {
        await Promise.all(
          selectedItems.map(async (id) => {
            if (cachedItems.some((item) => item._id === id)) return;

            const { data } = await api.brokers.byIds([id]);
            this.items = data;
          }),
        );
      } catch (error) {
        const errorMessage = error.response?.data.message ?? error.message;
        this.$store.dispatch('alert/showAlert', { errorList: [errorMessage] });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

import { cloneDeep, isPlainObject } from 'lodash';

const shouldOverwriteValue = (oldValue, newValue) => (
  typeof oldValue === typeof newValue
  || oldValue === null
  || newValue === null
);

function fillSchema(schema, data = {}) {
  const newData = cloneDeep(schema);

  Object.keys(schema).forEach((key) => {
    if (typeof data[key] === 'undefined') {
      return;
    }

    if (isPlainObject(schema[key])) {
      newData[key] = fillSchema(schema[key], data[key]);
      return;
    }

    if (shouldOverwriteValue(schema[key], data[key])) {
      newData[key] = Array.isArray(data[key]) ? cloneDeep(data[key]) : data[key];
    }
  });

  return newData;
}

export default fillSchema;

<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="isLoading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-switch
            label="shareRefPrice"
            inset

            v-model.trim="formFields.shareRefPrice"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-switch
            label="shareOptionsRefPrice"
            inset

            v-model.trim="formFields.shareOptionsRefPrice"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-switch
            label="futureOptionsRefPrice"
            inset

            v-model.trim="formFields.futureOptionsRefPrice"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Tol. de performance"
            suffix="%"

            :value="formFields.pFeeTolerance"
            @input="formFields.pFeeTolerance = vMask.unmaskNumber($event)"
          />

          <numeric-input
            label="Tol. de valor de ações"
            suffix="%"

            :value="formFields.sharesValuesTolerance"
            @input="formFields.sharesValuesTolerance = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Tol. de quantidade de cotas"

            :value="formFields.shareQuantityTolerance"
            @input="formFields.shareQuantityTolerance = vMask.unmaskNumber($event)"
          />

          <numeric-input
            label="Tol. de quantidade de cotas de fundos"

            :value="formFields.fundsQuantityTolerance"
            @input="formFields.fundsQuantityTolerance = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data inicial"
            :hint="formFields.startDate ? 'Utilize o formato YYYY-MM-DD' : 'Campo obrigatório'"
            :persistent-hint="!formFields.startDate"

            v-model="formFields.startDate"
            :rules="[vRules.requiredField, vRules.validDate]"
          />

          <date-picker-field-v2
            label="Data final"
            hint="Utilize o formato YYYY-MM-DD"
            clearable

            v-model="formFields.endDate"
            :rules="[vRules.validDate]"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="isLoading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || isLoading"
            :loading="isLoading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { round } from 'lodash';

import { requiredField, validDate } from '@/utils/validators';
import { unmaskNumber } from '@/utils/mask-utils';
import fillSchema from '@/utils/fill-schema';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  shareRefPrice: true,
  shareOptionsRefPrice: true,
  futureOptionsRefPrice: true,

  pFeeTolerance: null,
  fundsQuantityTolerance: null,
  shareQuantityTolerance: null,
  sharesValuesTolerance: null,
  startDate: null,
  endDate: null,
};

export default {
  name: 'CalcConfigForm',

  components: {
    DatePickerFieldV2,
    NumericInput,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: null,
    },

    submitBtnText: {
      type: String,
      default: null,
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData ?? {}),
    isFormValid: false,

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
    },
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText ?? 'Descartar alterações',
      submitBtn: vm.submitBtnText ?? 'Enviar',
    }),
  },

  methods: {
    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData ?? {});
    },

    submitForm() {
      const parseOptionalNumber = (val) => ((!val && val !== 0) ? null : Number(val));
      const parseOptionalPercentage = (val) => ((!val && val !== 0) ? null : round(val / 100, 10));

      this.$emit('submit', {
        ...this.formFields,
        pFeeTolerance: parseOptionalPercentage(this.formFields.pFeeTolerance),
        sharesValuesTolerance: parseOptionalPercentage(this.formFields.sharesValuesTolerance),
        shareQuantityTolerance: parseOptionalNumber(this.formFields.shareQuantityTolerance),
        fundsQuantityTolerance: parseOptionalNumber(this.formFields.fundsQuantityTolerance),
      });
    },
  },
};
</script>

<template>
  <v-form ref="form" @submit.prevent="submitForm">
    <v-container>
      <v-row dense>
        <v-col>
          <v-btn small text color="primary" @click="() => $router.go(-1)" exact>
            <v-icon small left> mdi-arrow-left </v-icon>
            Voltar
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-title class="justify-center white--text primary mb-3">
              {{ texts.title }}
            </v-card-title>
            <v-card-text>
              <general-info-form
                ref="generalInfoForm"
                :initial-data="initialData"
                @update:fields="updateFormFields"
                @update:validity="updateValidity"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-text>
              <purchase-info-form
                ref="purchaseInfoForm"
                :initial-data="initialData.purchase"
                @update:fields="updateFormFields"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-text>
              <withdrawal-info-form
                ref="withdrawalInfoForm"
                :initial-data="initialData.withdrawal"
                @update:fields="updateFormFields"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense justify="end" class="mt-4" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn text block color="error" :disabled="loading" @click="discardChanges">
            <v-icon small left> mdi-refresh </v-icon>
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fillSchema from '@/utils/fill-schema';

import GeneralInfoForm from './GeneralInfoForm.vue';
import PurchaseInfoForm from './PurchaseInfoForm.vue';
import WithdrawalInfoForm from './WithdrawalInfoForm.vue';

const formSchema = {
  fundId: null,
  isOpening: false,
  shortName: null,
  className: null,
  manager: null,
  administrator: null,

  selicAccount: null,
  selicGuaranteeAccount: null,
  cetipAccount: null,
  cblcAccount: null,
  custodyAccount: {
    bank: null,
    agency: null,
    account: null,
  },
  brokerAccount: [],

  startDate: null,
  endDate: null,
  startQuota: null,

  purchase: {
    daysToConversion: null,
    daysToConversionType: null,
    daysToSettlement: null,
    daysToSettlementType: null,
    minimumInitialPurchase: null,
    minimumAdditionalPurchase: null,
  },

  withdrawal: {
    daysToConversion: null,
    daysToConversionType: null,
    daysToSettlement: null,
    daysToSettlementType: null,
    minimumWithdrawal: null,
  },

  name: null,
  serial: null,
  legalName: null,
  document: null,
};

export default {
  name: 'HouseFundsForm',

  components: {
    GeneralInfoForm,
    PurchaseInfoForm,
    WithdrawalInfoForm,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: null,
    },

    submitBtnText: {
      type: String,
      default: null,
    },
  },

  data: (vm) => ({
    isFormValid: false,
    formFields: fillSchema(formSchema, vm.initialData ?? {}),
  }),

  computed: {
    isCreationForm: (vm) => !vm.initialData?._id,

    texts: (vm) => ({
      title: vm.isCreationForm ? 'Adicionar Novo Fundo à Casa' : 'Editar Fundo da Casa',
      discardBtn: vm.discardBtnText ?? 'Descartar alterações',
      submitBtn: vm.submitBtnText ?? 'Enviar',
    }),
  },

  methods: {
    updateFormFields(fields) {
      this.formFields = fillSchema(formSchema, { ...this.formFields, ...fields });
    },

    updateValidity(isValid) {
      this.isFormValid = isValid;
    },

    discardChanges() {
      this.$refs.generalInfoForm.discardChanges();
      this.$refs.purchaseInfoForm.discardChanges();
      this.$refs.withdrawalInfoForm.discardChanges();

      this.formFields = fillSchema(formSchema, this.initialData ?? {});
    },

    submitForm() {
      this.$emit('submit', this.formFields);
    },
  },
};
</script>

<template>
  <v-container class="housefunds-details">
    <v-progress-linear
      top
      absolute
      indeterminate
      color="#9d6c32"
      :active="isFetchingData"
    />

    <template v-if="hasFoundData">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="primary white--text">
                {{ details.name }}

                <v-spacer />

                <v-btn icon small color="white" :to="`/housefunds/${houseFundId}/edit`" v-if="isElevatedUser">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-subtitle class="primary white--text">
                CNPJ: {{ houseFundDocument }}
              </v-card-subtitle>

              <house-fund-general-info :details="details" />
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <v-card height="100%">
              <v-card-title>
                Dados de aplicação
              </v-card-title>

              <house-fund-purchase-info :info="details.purchase" />
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <v-card height="100%">
              <v-card-title>
                Dados de resgate
              </v-card-title>

              <house-fund-withdrawal-info :info="details.withdrawal" />
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-title>
                Corretoras
              </v-card-title>

              <house-fund-broker-accounts :items="details.brokerAccount" />
            </v-card>
          </v-col>

          <v-col cols="12">
            <house-fund-adm-fee
              :house-fund-id="houseFundId"
              :items="details.admFee"
              :loading="isFetchingData"
              :readonly="!isElevatedUser"
              @refresh-data="() => fetchData()"
            />
          </v-col>

          <v-col cols="12">
            <house-fund-performance-fee
              :house-fund-id="houseFundId"
              :items="details.performanceFee"
              :loading="isFetchingData"
              :readonly="!isElevatedUser"
              @refresh-data="() => fetchData()"
            />
          </v-col>

          <v-col cols="12">
            <house-fund-external-ids
              :house-fund-id="houseFundId"
              :items="details.externalIds"
              :loading="isFetchingData"
              :readonly="!isElevatedUser"
              @refresh-data="() => fetchData()"
            />
          </v-col>

          <v-col cols="12" v-if="isElevatedUser">
            <house-fund-calc-config
              :house-fund-id="houseFundId"
              :loading="isFetchingData"
              :config-list="details.calcConfig || []"
              @refresh-data="() => fetchData()"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-else-if="hasRequestedData">
      <empty-state
        icon="mdi-home-remove-outline"
        title="Fundo não encontrado"
        message="O fundo solicitado não pôde ser encontrado."
      />
    </template>
  </v-container>
</template>

<script>
import api from '@/services/api';

import EmptyState from '@/components/global/EmptyState.vue';
import HouseFundGeneralInfo from './HouseFundGeneralInfo.vue';
import HouseFundCalcConfig from './calcConfig/HouseFundCalcConfig.vue';
import HouseFundAdmFee from './admFee/HouseFundAdmFee.vue';
import HouseFundPerformanceFee from './performanceFee/HouseFundPerformanceFee.vue';
import HouseFundExternalIds from './externalIds/HouseFundExternalIds.vue';
import HouseFundPurchaseInfo from './HouseFundPurchaseInfo.vue';
import HouseFundWithdrawalInfo from './HouseFundWithdrawalInfo.vue';
import HouseFundBrokerAccounts from './brokerAccounts/HouseFundBrokerAccounts.vue';

export default {
  name: 'HouseFundsManagementView',

  components: {
    EmptyState,
    HouseFundGeneralInfo,
    HouseFundCalcConfig,
    HouseFundAdmFee,
    HouseFundPerformanceFee,
    HouseFundExternalIds,
    HouseFundPurchaseInfo,
    HouseFundWithdrawalInfo,
    HouseFundBrokerAccounts,
  },

  props: {
    houseFundId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    details: null,
    isFetchingData: false,
    hasRequestedData: false,
  }),

  computed: {
    isElevatedUser: (vm) => ['admin', 'backoffice'].includes(vm.$store.state.user?.acl),
    hasFoundData: (vm) => Object.keys(vm.details ?? {}).length > 0,
    houseFundDocument: (vm) => vm.details?.serial ?? '',
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.houseFunds.getHouseFundDetails(this.houseFundId);

        this.details = data;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isFetchingData = false;
        this.hasRequestedData = true;
      }
    },
  },
};
</script>

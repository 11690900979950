<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="isLoading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-select
            label="Tipo de taxa"

            :items="typeOptions"
            v-model="formFields.type"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data inicial"
            :hint="formFields.startDate ? 'Utilize o formato YYYY-MM-DD' : 'Campo obrigatório'"
            :persistent-hint="!formFields.startDate"

            v-model="formFields.startDate"
            :rules="[vRules.requiredField, vRules.validDate]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data final"
            hint="Utilize o formato YYYY-MM-DD"
            clearable

            v-model="formFields.endDate"
            :rules="[vRules.validDate]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Valor da taxa"
            suffix="%"

            :value="formFields.annualFee"
            @input="formFields.annualFee = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Mínimo mensal"

            :value="formFields.minimumMonthlyValue"
            :options="{ autoDecimalDigits: true, precision: 2 }"
            @input="formFields.minimumMonthlyValue = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Dias de liquidação"

            :value="formFields.settlementDays"
            :options="{ precision: 0, valueRange: { min: 0 } }"
            @input="formFields.settlementDays = vMask.unmaskNumber($event)"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="isLoading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || isLoading"
            :loading="isLoading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { round } from 'lodash';

import { unmaskNumber } from '@/utils/mask-utils';
import { requiredField, validDate } from '@/utils/validators';
import fillSchema from '@/utils/fill-schema';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  type: 'admFee',
  startDate: null,
  endDate: null,

  annualFee: null,
  minimumMonthlyValue: 0,
  settlementDays: 5,
};

export default {
  name: 'AdmFeeForm',

  components: {
    DatePickerFieldV2,
    NumericInput,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: null,
    },

    submitBtnText: {
      type: String,
      default: null,
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData ?? {}),
    isFormValid: false,

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
    },

    typeOptions: [
      { value: 'admFee', text: 'Administração' },
      { value: 'custodyFee', text: 'Custódia' },
    ],
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText ?? 'Descartar alterações',
      submitBtn: vm.submitBtnText ?? 'Enviar',
    }),
  },

  methods: {
    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData ?? {});
    },

    submitForm() {
      const parseOptionalNumber = (val) => ((!val && val !== 0) ? null : Number(val));
      const parseOptionalPercentage = (val) => ((!val && val !== 0) ? null : round(val / 100, 10));

      this.$emit('submit', {
        ...this.formFields,
        annualFee: parseOptionalPercentage(this.formFields.annualFee),
        minimumMonthlyValue: parseOptionalNumber(this.formFields.minimumMonthlyValue),
        settlementDays: parseOptionalNumber(this.formFields.settlementDays),
      });
    },
  },
};
</script>

<!--
  Simulate Vue Material's Empty State component
  https://vuematerial.io/components/empty-state/
-->

<template>
  <div class="empty-state text-center">
    <v-icon
      v-if="icon"
      v-text="icon"
      size="200"
      color="grey lighten-1"
      class="mb-4"
    />

    <p class="text-h4" v-if="title">
      {{ title }}
    </p>

    <p class="text-subtitle-1" v-if="message">
      {{ message }}
    </p>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'EmptyState',

  props: {
    icon: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    message: {
      type: String,
      default: '',
    },
  },
};
</script>

import { isNumber, isNil } from 'lodash';

export const uppercased = {
  mask: 'U*',
  tokens: {
    U: {
      pattern: /./,
      uppercase: true,
    },
  },
};

export const alphanumericUnderscored = {
  mask: 'A*',
  tokens: {
    A: {
      pattern: /[a-zA-Z0-9\s_]/,
      uppercase: true,
      transform: (s: string) => s.replaceAll(' ', '_'),
    },
  },
};

export function unmaskNumber(maskedValue?: string | number | null) {
  if (isNumber(maskedValue)) return maskedValue;
  if (!maskedValue || maskedValue === '-') return null;

  return Number(maskedValue.replaceAll('.', '').replace(',', '.'));
}

export function getFundMask(unmaskedValue?: string | number | null) {
  if (isNil(unmaskedValue)) return uppercased;

  const documentMask = '##.###.###/####-##';
  const documentRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;

  return String(unmaskedValue).match(documentRegex) ? documentMask : uppercased;
}

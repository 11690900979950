<template>
  <v-text-field
    ref="inputRef"

    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { watch } from '@vue/composition-api';
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'NumericInput',
  props: {
    value: Number,
    options: Object,
  },

  methods: {
    focus() {
      this.$refs.inputRef.focus();
    },
  },

  setup(props) {
    const { inputRef, setValue } = useCurrencyInput({
      currency: 'BRL',
      locale: 'pt-BR',
      currencyDisplay: 'hidden',
      accountingSign: false,
      autoSign: true,
      autoDecimalDigits: false,
      precision: { min: 0, max: 8 },
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      valueRange: undefined,
      useGrouping: true,

      ...props.options,
    }, true);

    watch(
      () => props.value,
      (value) => setValue(value),
    );

    return { inputRef };
  },
};
</script>

<template>
  <div>
    <v-data-table
      dense
      fixed-header
      :headers="headers"
      :items="items"
      item-key="_id"
      hide-default-footer
      :items-per-page="-1"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'HouseFundBrokerAccounts',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    headers: [
      {
        value: 'accountNumber',
        text: 'Conta',
      },

      {
        value: 'brokerId',
        text: 'Id da corretora',
      },

      {
        value: 'brokerCode',
        text: 'Código da corretora',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>

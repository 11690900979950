<template>
  <v-text-field
    v-bind="{ ...dynamicFieldAttrs, ...$attrs }"
    v-on="{ ...dynamicFieldListeners, ...$listeners }"
    v-maska="'####-##-##'"

    :value="value"
  >
    <template v-slot:append>
      <v-menu
        left
        offset-y
        nudge-bottom="8"
        max-width="300"
        :close-on-content-click="false"

        v-model="isPickerMenuVisible"
        v-bind="menuProps"
      >
        <template v-slot:activator="vMenuScope">
          <v-icon
            tabindex="-1"
            v-on="vMenuScope.on"
            v-bind="vMenuScope.attrs"
            :disabled="$attrs.disabled || $attrs.readonly"
          >
            mdi-calendar
          </v-icon>
        </template>

        <template v-slot:default>
          <v-date-picker
            v-bind="{ ...dynamicPickerAttrs, ...pickerProps }"

            no-title
            full-width

            locale="pt-BR"
            :value="pickerValue"

            @input="(value) => updateValue(value)"
            @change="(value) => updateValue(value)"
          />
        </template>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import moment from 'moment-loyall';
import { isISO8601, isLength } from 'validator';

function isValidPickerDate(rawValue) {
  const value = rawValue ?? '';

  return isISO8601(value, { strict: true }) && isLength(value, { min: 7 });
}

export default {
  name: 'DatePickerFieldV2',

  inheritAttrs: false,

  // NOTE: "value" must be set as a prop for v-model to work properly
  // @see (https://github.com/vuejs/vue/issues/8430)
  props: {
    value: {
      type: String,
      default: null,
    },

    menuProps: {
      type: Object,
      default: () => ({}),
    },

    pickerProps: {
      type: Object,
      default: () => ({}),
    },

    businessDaysOnly: {
      type: Boolean,
      default: false,
    },

    showPrevNext: {
      type: Boolean,
      default: false,
    },

    minDate: {
      type: String,
      default: null,
    },

    maxDate: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    isPickerMenuVisible: false,
  }),

  computed: {
    pickerValue: (vm) => (isValidPickerDate(vm.value) ? vm.value : null),

    dynamicFieldAttrs: (vm) => {
      const attrs = {};

      if (vm.showPrevNext) {
        attrs.prependIcon = 'mdi-arrow-left';
        attrs.appendOuterIcon = 'mdi-arrow-right';
      }

      return attrs;
    },

    dynamicFieldListeners: (vm) => {
      const listeners = {};

      if (vm.showPrevNext) {
        listeners['click:prepend'] = () => vm.prevDate();
        listeners['click:append-outer'] = () => vm.nextDate();
      }

      return listeners;
    },

    dynamicPickerAttrs: (vm) => {
      const attrs = { allowedDates: vm.getAllowedDates };

      if (vm.minDate) {
        attrs.min = vm.minDate;
      }

      if (vm.maxDate) {
        attrs.max = vm.maxDate;
      }

      return attrs;
    },
  },

  methods: {
    updateValue(value) {
      this.isPickerMenuVisible = false;

      this.$emit('input', value);
      this.$emit('change', value);
    },

    getAllowedDates(date) {
      return this.businessDaysOnly ? moment(date).isBusinessDay('brasil') : true;
    },

    prevDate() {
      const currentDate = moment(this.value);

      const targetDate = (this.businessDaysOnly
        ? currentDate.prevBusinessDay('brasil')
        : currentDate.subtract(1, 'day')
      );

      if (this.minDate && targetDate.isBefore(this.minDate)) {
        return;
      }

      this.updateValue(targetDate.format('YYYY-MM-DD'));
    },

    nextDate() {
      const currentDate = moment(this.value);

      const targetDate = (this.businessDaysOnly
        ? currentDate.nextBusinessDay('brasil')
        : currentDate.add(1, 'day')
      );

      if (this.maxDate && targetDate.isAfter(this.maxDate)) {
        return;
      }

      this.updateValue(targetDate.format('YYYY-MM-DD'));
    },
  },
};
</script>

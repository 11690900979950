<template>
  <v-form v-model="isFormValid">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <funds-selector
            label="Fundo de referência"
            :hint="fundDocument ? `CNPJ do fundo: ${fundDocument}` : 'Digite para buscar'"
            persistent-hint
            return-object
            item-value="_id"
            item-text="shortName"
            v-model="formFields.fundId"
            @change="updateFundInfo"
            :rules="[vRules.requiredField]"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            label="Nome curto"
            hint="Identificador interno único do fundo"
            v-model="formFields.shortName"
            v-maska="vMask.uppercased"
            :rules="[vRules.requiredField]"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            label="Classe"
            hint="Categorização interna do fundo"
            v-model="formFields.className"
            v-maska="vMask.uppercased"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <administrators-selector
            label="Administrador"
            item-value="_id"
            item-text="name"
            v-model="formFields.administrator"
            :rules="[vRules.requiredField]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <managers-selector
            label="Gestor"
            item-value="_id"
            item-text="name"
            v-model="formFields.manager"
            :rules="[vRules.requiredField]"
          />
        </v-col>
      </v-row>

      <v-row class="py-6 mx-1"> <v-divider /> </v-row>

      <v-row dense>
        <v-col cols="12" sm="2" class="align-content-center text-body-1 font-weight-medium">
          Contas:
        </v-col>
        <v-col cols="12" sm="10">
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field label="Selic" v-maska="'#########'" v-model="formFields.selicAccount" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="Garantia Selic"
                v-maska="'#########'"
                v-model="formFields.selicGuaranteeAccount"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="CETIP" v-maska="'#########'" v-model="formFields.cetipAccount" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="CBLC" v-maska="'#########'" v-model="formFields.cblcAccount" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2" class="align-content-center text-body-1 font-weight-medium">
          Custódia:
        </v-col>
        <v-col cols="12" sm="10">
          <custody-account-input
            ref="custodyAccountInput"
            :initial-data="initialData.custodyAccount"
            @update="updateCustodyInfo"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2" class="align-content-center text-body-1 font-weight-medium">
          Corretoras:
        </v-col>
        <v-col cols="12" sm="10">
          <broker-accounts-input
            ref="brokerAccountsInput"
            :initial-data="initialData.brokerAccount"
            @update="updateBrokerInfo"
          />
        </v-col>
      </v-row>

      <v-row class="py-6 mx-1"> <v-divider /> </v-row>

      <v-row>
        <v-col cols="12" sm="3">
          <date-picker-field-v2
            label="Início da gestão"
            hint="Pode ser diferente da data inicial do fundo"
            v-model="formFields.startDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <date-picker-field-v2
            label="Fim da gestão"
            hint="Pode ser diferente da data final do fundo"
            v-model="formFields.endDate"
            :rules="[vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates, min: formFields.startDate }"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <numeric-input
            label="Cota inicial"
            :rules="[vRules.requiredField]"
            :options="vOptions.positive"
            :value="formFields.startQuota"
            @input="formFields.startQuota = vMask.unmaskNumber($event)"
          />
        </v-col>
        <v-col cols="12" sm="3" class="d-flex justify-center text-center">
          <v-switch
            label="Cota de abertura"
            persistent-hint
            hint="Desabilitar caso cota de fechamento"
            v-model="formFields.isOpening"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import moment from 'moment-loyall';

import fillSchema from '@/utils/fill-schema';
import { unmaskNumber, uppercased } from '@/utils/mask-utils';
import { requiredField, validDate } from '@/utils/validators';

import AdministratorsSelector from '@/components/global/AdministratorsSelector.vue';
import CustodyAccountInput from '@/components/global/CustodyAccountInput.vue';
import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import FundsSelector from '@/components/global/FundsSelector.vue';
import ManagersSelector from '@/components/global/ManagersSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';
import BrokerAccountsInput from '../brokerAccounts/BrokerAccountsInput.vue';

const formSchema = {
  fundId: null,
  isOpening: false,
  shortName: null,
  className: null,
  manager: null,
  administrator: null,

  selicAccount: null,
  selicGuaranteeAccount: null,
  cetipAccount: null,
  cblcAccount: null,
  custodyAccount: {
    bank: null,
    agency: null,
    account: null,
  },
  brokerAccount: [],

  startDate: null,
  endDate: null,
  startQuota: null,

  name: null,
  serial: null,
  legalName: null,
  document: null,
};

export default {
  name: 'GeneralInfoForm',

  components: {
    FundsSelector,
    ManagersSelector,
    AdministratorsSelector,
    CustodyAccountInput,
    BrokerAccountsInput,
    DatePickerFieldV2,
    NumericInput,
  },

  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: null,
    },

    submitBtnText: {
      type: String,
      default: null,
    },
  },

  data: (vm) => ({
    isFormValid: false,

    formFields: fillSchema(formSchema, vm.initialData ?? {}),

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
      uppercased,
    },

    vOptions: {
      positive: {
        valueRange: { min: 0 },
      },
    },

    dayTypeOptions: [
      { value: 'WORKDAYS', text: 'Úteis' },
      { value: 'CALENDAR_DAYS', text: 'Corridos' },
    ],
  }),

  computed: {
    fundDocument: (vm) => vm.formFields.document ?? '',
  },

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('update:fields', this.formFields);
      },
    },

    isFormValid: {
      immediate: true,
      handler() {
        this.$emit('update:validity', this.isFormValid);
      },
    },
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    updateFundInfo(data) {
      this.$set(this.formFields, 'fundId', data._id);
      this.$set(this.formFields, 'name', data.name);
      this.$set(this.formFields, 'legalName', data.name);
      this.$set(this.formFields, 'document', data.document);
      this.$set(this.formFields, 'serial', data.serial);
    },

    updateCustodyInfo(data) {
      this.$set(this.formFields, 'custodyAccount', data);
    },

    updateBrokerInfo(data) {
      this.$set(this.formFields, 'brokerAccount', data);
    },

    discardChanges() {
      this.$refs.custodyAccountInput.discardChanges();
      this.$refs.brokerAccountsInput.discardChanges();

      this.formFields = fillSchema(formSchema, this.initialData ?? {});
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input--switch {
  margin: auto;
}
</style>

<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" class="text-body-1 font-weight-medium"> Dias para Aplicação </v-col>
        <v-col cols="12" sm="6">
          <v-text-field label="Cotização" v-maska="'###'" v-model="formFields.daysToConversion" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="daysToConversionType"
            label="Tipo"
            :items="dayTypeOptions"
            v-model="formFields.daysToConversionType"
            @focus="() => showDayTypeOptions('daysToConversionType')"
            @mousedown.prevent
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field label="Liquidação" v-maska="'###'" v-model="formFields.daysToSettlement" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="daysToSettlementType"
            label="Tipo"
            :items="dayTypeOptions"
            v-model="formFields.daysToSettlementType"
            @focus="() => showDayTypeOptions('daysToSettlementType')"
            @mousedown.prevent
          />
        </v-col>
        <v-col cols="12" sm="12" class="text-body-1 font-weight-medium"> Valores para Aplicação </v-col>
        <v-col cols="12" sm="6">
          <numeric-input
            label="Mínimo inicial"
            :options="vOptions.positiveValue"
            :value="formFields.minimumInitialPurchase"
            @input="formFields.minimumInitialPurchase = vMask.unmaskNumber($event)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <numeric-input
            label="Mínimo adicional"
            :options="vOptions.positiveValue"
            :value="formFields.minimumAdditionalPurchase"
            @input="formFields.minimumAdditionalPurchase = vMask.unmaskNumber($event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fillSchema from '@/utils/fill-schema';
import { unmaskNumber } from '@/utils/mask-utils';

import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  daysToConversion: null,
  daysToConversionType: null,
  daysToSettlement: null,
  daysToSettlementType: null,
  minimumInitialPurchase: null,
  minimumAdditionalPurchase: null,
};

export default {
  name: 'PurchaseInfoForm',

  components: {
    NumericInput,
  },

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('update:fields', { purchase: this.formFields });
      },
    },
  },

  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData ?? {}),

    vMask: {
      unmaskNumber,
    },

    vOptions: {
      positiveValue: {
        autoDecimalDigits: true,
        valueRange: { min: 0 },
        precision: 2,
      },
    },

    dayTypeOptions: [
      { value: 'WORKDAYS', text: 'Úteis' },
      { value: 'CALENDAR_DAYS', text: 'Corridos' },
    ],
  }),

  methods: {
    showDayTypeOptions(ref) {
      this.$refs[ref].activateMenu();
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData ?? {});
    },
  },
};
</script>

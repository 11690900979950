import Decimal from 'decimal.js';
import {
  isArray, isEmpty, isNil, isObject, mapValues, omitBy,
} from 'lodash';
import moment from 'moment-loyall';

export const removeEmpties = (data: Record<string, unknown> | number | string): any => {
  if (isArray(data)) return data.map(removeEmpties);
  if (isObject(data)) {
    return omitBy(
      mapValues(
        omitBy(data, (el) => isNil(el) || el === ''),
        removeEmpties,
      ),
      (el) => isObject(el) && isEmpty(el),
    );
  }
  return data;
};

export const parseDecimal = (
  num?: number | string | null | { $numberDecimal: number | string },
): number | null => {
  if (isNil(num)) {
    return null;
  }
  if (isObject(num) && '$numberDecimal' in num) {
    return Number(num.$numberDecimal);
  }
  return Number(num);
};

export const parseDate = (date?: moment.Moment | Date | string | null): string | null => {
  if (isNil(date)) {
    return null;
  }

  return moment.utc(date).format('YYYY-MM-DD');
};

export const parseToPercentage = (num?: number | string | null): number | null => {
  if (isNil(num)) {
    return null;
  }

  return new Decimal(num).times(100).toNumber();
};

export const parseFromPercentage = (num?: number | string | null): number | null => {
  if (isNil(num)) {
    return null;
  }

  return new Decimal(num).div(100).toNumber();
};

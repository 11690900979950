<template>
  <v-container class="housefunds-management">
    <h1 class="text-h5 mb-2">Fundos da Casa</h1>

    <div class="mb-4 text-right">
      <v-btn small rounded color="primary" class="mx-1" @click="() => fetchData()">
        <v-icon small left>
          mdi-refresh
        </v-icon>

        Atualizar
      </v-btn>

      <v-btn small rounded color="primary" class="mx-1" to="/housefunds/new" v-if="isElevatedUser">
        <v-icon small left>
          mdi-plus
        </v-icon>

        Novo
      </v-btn>
    </div>

    <v-text-field
      label="Buscar..."
      solo
      hide-details
      class="mb-4"

      v-model="requestFilters.q"
      clearable

      append-icon="mdi-magnify"
      @click:append="() => fetchData()"
      @keypress.enter="() => fetchData()"
    />

    <v-card>
      <v-card-text>
        <house-funds-table
          fixed-header
          dense

          must-sort
          sort-by="endDate"

          :items-per-page="-1"
          hide-default-footer

          :items="results"
          :loading="isFetchingData"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { debounce } from 'lodash';

import api from '@/services/api';
/* eslint-disable-next-line */
import { loadValueFromUrl, saveValuesOnUrl } from '@/utils/router-utils';

import HouseFundsTable from './HouseFundsTable.vue';

export default {
  name: 'HouseFundsManagementView',

  components: {
    HouseFundsTable,
  },

  data: () => ({
    requestFilters: {
      q: loadValueFromUrl('q') ?? '',
    },

    results: [],
    isFetchingData: false,
  }),

  computed: {
    isElevatedUser: (vm) => ['admin', 'backoffice'].includes(vm.$store.state.user?.acl),
  },

  watch: {
    requestFilters: {
      deep: true,
      immediate: true,
      handler(newFilters) {
        saveValuesOnUrl({ ...newFilters });

        this.fetchData();
      },
    },
  },

  methods: {
    fetchData: debounce(async function debouncedFetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.houseFunds.getHouseFundsList({ params: this.requestFilters });

        this.results = data.results;
      } catch (error) {
        console.log(error);
      } finally {
        this.isFetchingData = false;
      }
    }, 200),
  },
};
</script>

<template>
  <v-card>
    <v-card-title>
      Taxas de administração

      <v-spacer />

      <v-btn rounded small color="primary" @click="() => showUpsertDialog()" v-if="!readonly">
        <v-icon small left>
          mdi-plus
        </v-icon>

        Incluir
      </v-btn>
    </v-card-title>

    <v-card-text>
      <adm-fee-table
        :items="items"
        :loading="loading"
        :readonly="readonly"

        dense
        fixed-header

        must-sort
        sort-by="startDate"
        sort-desc

        hide-default-footer
        :items-per-page="-1"

        @click:edit="(item) => showEditionForm(item)"
        @click:delete="(item) => showExclusionDialog(item)"
      />
    </v-card-text>

    <v-dialog v-model="isUpsertDialogOpen" max-width="600">
      <v-card>
        <v-card-title>
          {{ upsertDialogTitle }}
        </v-card-title>

        <div class="px-4">
          <adm-fee-form
            ref="upsertForm"

            :isLoading="isSendingData"
            :initialData="selectedItem || {}"

            discard-btn-text="Cancelar"
            @click:discard="() => hideUpsertDialog()"
            @submit="(data) => handleUpsertSubmit(data)"
          />
        </div>
      </v-card>
    </v-dialog>

    <contextual-dialog
      v-model="isExclusionDialogOpen"
      icon="mdi-delete"
      :icon-color="isSendingData ? 'grey' : 'error'"
      :loading="isSendingData"

      title="Deletar registro?"
      message="Deseja realmente deletar o registro?"
    >
      <v-card-actions class="justify-center">
        <v-btn color="primary" :disabled="isSendingData" @click="() => deleteItem()">
          Deletar
        </v-btn>
      </v-card-actions>
    </contextual-dialog>
  </v-card>
</template>

<script>
import { isNil, round } from 'lodash';
import api from '@/services/api';

import ContextualDialog from '@/components/global/ContextualDialog.vue';

import AdmFeeForm from './AdmFeeForm.vue';
import AdmFeeTable from './AdmFeeTable.vue';

export default {
  name: 'HouseFundAdmFee',

  components: {
    ContextualDialog,
    AdmFeeForm,
    AdmFeeTable,
  },

  props: {
    houseFundId: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isSendingData: false,

    isUpsertDialogOpen: false,
    isExclusionDialogOpen: false,
    selectedItem: null,
  }),

  computed: {
    upsertDialogTitle: (vm) => (vm.selectedItem
      ? 'Editar taxa de administração'
      : 'Incluir taxa de administração'
    ),
  },

  watch: {
    isUpsertDialogOpen(newState) {
      if (!newState) {
        this.selectedItem = null;
      }

      this.$nextTick(() => this.$refs.upsertForm?.discardChanges());
    },

    isExclusionDialogOpen(newState) {
      if (!newState) {
        this.selectedItem = null;
      }
    },
  },

  methods: {
    fetchData() {
      this.$emit('refresh-data');
    },

    showUpsertDialog() {
      this.isUpsertDialogOpen = true;
    },

    hideUpsertDialog() {
      this.isUpsertDialogOpen = false;
    },

    handleUpsertSubmit(newData) {
      if (this.selectedItem) {
        this.updateItem(this.selectedItem._id, newData);
      } else {
        this.createItem(newData);
      }
    },

    // ----------
    //  Creation
    // ----------

    async createItem(rawData) {
      this.isSendingData = true;

      try {
        const newData = {};

        // Remove empty values
        Object.entries(rawData).forEach(([key, value]) => {
          if (!isNil(value) && String(value).length > 0) {
            newData[key] = value;
          }
        });

        await api.houseFunds.addAdmFee(this.houseFundId, newData);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Taxa incluída com sucesso!',
          message: 'As informações foram inseridas corretamente.',
        });

        this.fetchData();
        this.hideUpsertDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: 'Não foi possível incluir a taxa de administração',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    // ---------
    //  Edition
    // ---------

    showEditionForm(item) {
      const getTrueValue = (field) => Number(field.$numberDecimal ?? field);
      const decimalToPercentage = (val) => (round(val * 100, 8));
      const getDate = (ISODate) => ISODate.substring(0, 10);

      const formData = { ...item };

      if (item.annualFee) {
        formData.annualFee = decimalToPercentage(getTrueValue(item.annualFee));
      }

      if (item.minimumMonthlyValue) {
        formData.minimumMonthlyValue = getTrueValue(item.minimumMonthlyValue);
      }

      if (item.startDate) {
        formData.startDate = getDate(item.startDate);
      }

      if (item.endDate) {
        formData.endDate = getDate(item.endDate);
      }

      this.selectedItem = formData;

      this.showUpsertDialog();
    },

    async updateItem(admFeeId, data) {
      this.isSendingData = true;

      try {
        await api.houseFunds.updateAdmFee(admFeeId, data);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Taxa atualizada com sucesso!',
          message: 'As informações foram atualizadas corretamente.',
        });

        this.fetchData();
        this.hideUpsertDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: 'Não foi possível editar a taxa de administração',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    // -----------
    //  Exclusion
    // -----------

    showExclusionDialog(item) {
      this.selectedItem = item;
      this.isExclusionDialogOpen = true;
    },

    async deleteItem() {
      this.isSendingData = true;

      try {
        await api.houseFunds.deleteAdmFee(this.selectedItem._id);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Registro excluído com sucesso!',
          message: 'A taxa de administração foi removida corretamente.',
        });

        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: 'Não foi possível deletar a taxa de administração',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isExclusionDialogOpen = false;
        this.isSendingData = false;
      }
    },
  },
};
</script>

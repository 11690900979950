<template>
  <v-data-table
    item-key="_id"
    :headers="headers"

    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item.actions="{ item }">
      <v-btn small icon color="primary" @click="() => $emit('click:edit', item)">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>

      <v-btn small icon color="error" @click="() => $emit('click:delete', item)">
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <template v-slot:item.fee="{ item }">
      {{ formatPercentage(getTrueValue(item.fee)) }}
    </template>

    <template v-slot:item.renewalMonths="{ item }">
      {{ getMonthsString(item.renewalMonths) }}
    </template>

    <template v-slot:item.startDate="{ item }">
      {{ formatDate(item.startDate) }}
    </template>

    <template v-slot:item.endDate="{ item }">
      {{ formatDate(item.endDate) }}
    </template>
  </v-data-table>
</template>

<script>
import { formatMoney, formatDate, formatPercentage } from '@/utils/format-utils';

export default {
  name: 'PerformanceFeeTable',

  inheritAttrs: false,

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rawHeaders: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '98px',
      },

      {
        value: 'fee',
        text: 'Taxa',
      },

      {
        value: 'benchmarkName',
        text: 'Benchmark',
      },

      {
        value: 'renewalMonths',
        text: 'Meses de renovação',
      },

      {
        value: 'settlementDays',
        text: 'Dias de Liquidação',
      },

      {
        value: 'minimumMonthsPeriod',
        text: 'Meses mínimos',
      },

      {
        value: 'startDate',
        text: 'Data Inicial',
      },

      {
        value: 'endDate',
        text: 'Data Final',
      },
    ],
  }),

  computed: {
    headers: (vm) => (vm.readonly
      ? vm.rawHeaders.filter((item) => item.value !== 'actions')
      : vm.rawHeaders
    ),
  },

  methods: {
    formatMoney,
    formatDate,
    formatPercentage,

    getTrueValue(field) {
      return field?.$numberDecimal ?? field;
    },

    getMonthsString(monthArray) {
      const MONTHS = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ];

      return monthArray.map((monthIndex) => MONTHS[monthIndex]).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>

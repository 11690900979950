<template>
  <v-container>
    <v-progress-linear top absolute indeterminate color="#9d6c32" :active="isFetchingData" />

    <template v-if="hasFoundData">
      <house-funds-form
        :initial-data="sanitizedInitialData"
        :loading="isSendingData"
        @submit="(data) => updateHouseFund(data)"
      />
    </template>
    <template v-else-if="hasRequestedData">
      <empty-state
        icon="mdi-home-remove-outline"
        title="Fundo não encontrado"
        message="O fundo solicitado não está cadastrado como fundo da casa"
      />
    </template>
  </v-container>
</template>

<script>
import api from '@/services/api';
import { parseDate, parseDecimal } from '@/utils/parse-utils';

import EmptyState from '@/components/global/EmptyState.vue';
import HouseFundsForm from './form/HouseFundsForm.vue';

export default {
  name: 'HouseFundsEditView',

  components: {
    EmptyState,
    HouseFundsForm,
  },

  props: {
    houseFundId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    details: null,
    isSendingData: false,
    isFetchingData: false,
    hasRequestedData: false,
  }),

  computed: {
    hasFoundData: (vm) => Object.keys(vm.details ?? {}).length > 0,

    sanitizedInitialData: (vm) => {
      if (!vm.hasFoundData) {
        return {};
      }

      const data = { ...vm.details };

      data.startDate = parseDate(data.startDate);
      data.endDate = parseDate(data.endDate);
      data.startQuota = parseDecimal(data.startQuota);

      if (data.purchase) {
        data.purchase.minimumInitialPurchase = parseDecimal(data.purchase.minimumInitialPurchase);
        data.purchase.minimumAdditionalPurchase = parseDecimal(
          data.purchase.minimumAdditionalPurchase,
        );
      }

      if (data.withdrawal) {
        data.withdrawal.minimumWithdrawal = parseDecimal(data.withdrawal.minimumWithdrawal);
      }

      return data;
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.houseFunds.getHouseFundDetails(this.houseFundId);

        this.details = data;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isFetchingData = false;
        this.hasRequestedData = true;
      }
    },

    async updateHouseFund(data) {
      this.isSendingData = true;

      try {
        await api.houseFunds.updateHouseFund(this.houseFundId, data);
        await this.updateFund(data); // Courtesy update of fund in assets collection

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Fundo atualizado',
          message: 'Dados atualizados com sucesso.',
        });

        this.$router.replace(`/housefunds/${this.houseFundId}`);
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    async updateFund(data) {
      const renameBusinessDays = (daysType) => (daysType === 'WORKDAYS' ? 'BUSINESS_DAYS' : daysType);

      // There are some known mismatches between assets (left) and portfolios (right) models
      // 'Convertion' and 'Additionl' are expected typos
      const assetsFundsUpdate = {
        custodyBank: data?.custodyAccount?.bank,
        custodyAgency: data?.custodyAccount?.agency,
        custodyAccount: data?.custodyAccount?.account,
        purchase: {
          daysToConvertion: data?.purchase?.daysToConversion,
          daysToConvertionType: renameBusinessDays(data?.purchase?.daysToConversionType),
          daysToSettlement: data?.purchase?.daysToSettlement,
          daysToSettlementType: renameBusinessDays(data?.purchase?.daysToSettlementType),
          minimumInitialPurchase: data?.purchase?.minimumInitialPurchase,
          minimumAdditionlPurchase: data?.purchase?.minimumAdditionalPurchase,
        },
        withdrawal: {
          daysToConvertion: data?.withdrawal?.daysToConversion,
          daysToConvertionType: renameBusinessDays(data?.withdrawal?.daysToConversionType),
          daysToSettlement: data?.withdrawal?.daysToSettlement,
          daysToSettlementType: renameBusinessDays(data?.withdrawal?.daysToSettlementType),
          minimumWithdrawal: data?.withdrawal?.minimumWithdrawal,
        },
      };

      const response = await api.funds.updateById(data.fundId, assetsFundsUpdate);
      return response;
    },
  },
};
</script>

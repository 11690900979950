import { cloneDeep, isEqual, isNil } from 'lodash';
import router from '@/router';

export function saveValuesOnUrl(values: Record<string, any> = {}): void {
  const { query, path, hash } = router.currentRoute;

  const newQuery = cloneDeep(query);

  Object.entries(values).forEach(([key, value]) => {
    if (isNil(value) || value === '') {
      delete newQuery[key];
      return;
    }

    newQuery[key] = String(value);
  });

  // Prevent NavigationDuplicated error
  if (isEqual(query, newQuery)) {
    return;
  }

  router.replace({ path, hash, query: newQuery });
}

export function saveValueOnUrl(key: string, value: any): void {
  saveValuesOnUrl({ [key]: value });
}

export function loadValueFromUrl(key: string) {
  return router.currentRoute.query[key];
}

export function loadValuesFromUrl(...keys: string[]) {
  return keys.map((key) => loadValueFromUrl(key));
}

<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td>Nome curto</td>
              <td>{{ details.shortName }}</td>
            </tr>

            <tr>
              <td>Razão Social</td>
              <td>{{ details.legalName }}</td>
            </tr>

            <tr>
              <td>Cota Inicial</td>
              <td>{{ formatNumber(getDecimalValue(details.startQuota)) }}</td>
            </tr>

            <tr>
              <td>Classe</td>
              <td>{{ details.className }}</td>
            </tr>

            <tr>
              <td>Início da gestão</td>
              <td>{{ formatDate(details.startDate) }}</td>
            </tr>

            <tr>
              <td>Fim da gestão</td>
              <td>{{ formatDate(details.endDate) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>

      <v-col cols="12" sm="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td>Administrador</td>
              <td>{{ details.administratorDoc.name }}</td>
            </tr>

            <tr>
              <td>Gestor</td>
              <td>{{ details.managerDoc.name }}</td>
            </tr>

            <tr>
              <td>Moeda</td>
              <td>{{ details.currency }}</td>
            </tr>

            <tr>
              <td>Conta Selic</td>
              <td>{{ details.selicAccount || "-" }}</td>
            </tr>

            <tr>
              <td>Garantia Selic</td>
              <td>{{ details.selicGuaranteeAccount || "-" }}</td>
            </tr>

            <tr>
              <td>Custódia</td>
              <td>
                <v-row>
                  <v-col>
                    Banco<br/>{{ details.custodyAccount.bank }}
                  </v-col>
                  <v-col>
                    Agência<br/>{{ details.custodyAccount.agency }}
                  </v-col>
                  <v-col>
                    Conta<br/>{{ details.custodyAccount.account }}
                  </v-col>
                </v-row>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDate, formatNumber } from '@/utils/format-utils';

export default {
  name: 'HouseFundGeneralInfo',

  props: {
    details: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatDate,
    formatNumber,
    getDecimalValue: (field) => (field.$numberDecimal ?? field),
  },
};
</script>
